.navbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    padding: 0 10px;
    cursor: pointer;
    color: #DDDDDD;
    gap: .25rem;
}

.navbutton span {
    display: inline-block;
    position: relative;
    color: #DDDDDD;
}

.underline-hover-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: var(--red);
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
}

.underline-hover-animation-done::after{
    transform: scaleX(1);
    transform-origin:  bottom left;
}

.hamburger-react {
    width: 40px !important;
    height: 40px !important;
    margin-left: 10px !important;
}

.hamburger-react div:first-of-type{
    top: 15px !important;
    left: 10px !important;
}

.hamburger-react div:last-of-type{
    top: 27px !important;
    left: 10px !important;
}

.hamburger-react div{
    top: 21px !important;
    left: 10px !important;
}

@media only screen and (min-width: 769px) {
    .hamburger-react {
        display: none;
    }
}