.sidebar {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 60px);
    background-color: var(--secondary-transparency);
    backdrop-filter: blur(10px);
    z-index: 500;
    transform: translateX(-200vw);
    transition: transform 0.25s ease-out;
}

.sidebar-open {
    display: block;
    transform: translateX(0);
}

.header-area {
    width: 100%;
    height: 60px;
}

.buttons-area {
    width: 100%;
    box-sizing: border-box;
    font-size: 24px;
    padding: 10px 25px;
}

@media only screen and (min-width: 769px) {
    .sidebar {
        transform: translateX(-200vw);
    }
}