:root {
  --primary: #30475E;
  --secondary: #262f3b;
  --primary-transparency: #30475eb7;
  --secondary-transparency: #262f3bb7;
  --white: #EEEEEE;
  --red: #F05454;
  --darkblue: #222831;
  --darkblue-transparency: #222831b7;
  --discord: #7289da;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--secondary);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-transparency);
}

body {
  background-color: var(--darkblue) !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--white);
  position: relative;
  min-height: 100vh;
}

.wrapper {
  width: 100%;
  height: 0;
}

.fullscreen-backdop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  display: block;
  background-color: var(---darkblue-transparency)
}

.fit-content {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

.flex {
  display: flex;
}

.navlink {
  display: flex;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: var(--white);
  padding: 0 .5rem;
  transition: background .2s ease-in-out;
}

.navlink:hover {
  background: var(--red);
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-conent-center {
  justify-content: center;
}

.justify-conent-sapce-between {
  justify-content: space-between;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  color: var(--red);
}

.Input-input {
  color: var(--white) !important;

  &:disabled {
    -webkit-text-fill-color: var(--white) !important;
  }
}

.TextField-root {
  background: #1b2a3a !important;

  .MuiInputLabel-filled {
    color: var(--red) !important;
  }

  .MuiFormHelperText-root {
    color: var(--red) !important;
  }
}

.FilledInput-underline::before {
  border-bottom-color: var(--secondary) !important;
}

.FilledInput-underline::after {
  border-bottom-color: var(--red) !important;
}

.Button-contained {
  background: var(--primary) !important;
  color: var(--white) !important;

  &:hover {
    background: var(--red) !important;
  }
}

.MuiCircularProgress-colorPrimary {
  color: var(--primary) !important;
}

.MuiDivider-root {
  background: var(--primary-transparency) !important;
}

.MuiCard-root {
  background: var(--primary) !important;
  color: var(--white) !important;
}