.application-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.application-page .banner {
  background-image: url('../../images/src1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.application {
  display: flex;
  max-width: 1000px;
  width: 1000px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 1rem;
  color: var(--white);
  padding: 1rem 1rem 0 1rem;

}

.application-container {
  max-width: 1000px;
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: .5rem;
  margin: 1rem 0;
  padding: 0 1rem;

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--red);
  }

  h1 {
    color: var(--red);
  }

  span {
    color: var(--white);
  }
}

.discord-button {
  display: flex;
  background: var(--discord);
  padding: .5rem;
  border-radius: .5rem;
  color: var(--white);
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  img {
    height: 2rem;
    margin-right: .5rem;
  }
}

.question {
  display: flex;
  justify-content: justify;
  width: 100%;
  margin: 1rem 0 0 0;
  color: var(--white)
}