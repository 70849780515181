.fakeHeader {
  width: calc(100vw - 50px);
  height: 60px;
  padding: 10px 25px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  height: 60px;
  background: var(--primary-transparency);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  transition: background-color 0.25s ease-out;
  z-index: 1000;
}

.header {
  display: flex;
  max-width: 1000px;
  width: 1000px;
  height: 100%;
  padding: 0 1rem;
}

.full-color {
  background: var(--primary);
}

.headerLogo {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  a {
    height: 100%;
    display: flex;
    align-items: center;
  }

  a img {
    height: 75%;
  }
}

.headerNavigation {
  display: flex;
  height: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 769px) {
  .headerNavigation {
    display: none;
  }
}