.home-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .banner {
    background-image: url('../../images/src4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
  }
}

.home-widgets {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 1000px;
  gap: 1rem;
  padding: 1rem;
}

h1 {
  color: var(--red);
}