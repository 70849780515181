.home-widget-left {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-start;
  padding: 1rem 0;

  .img {
    width: 40%;

    img {
      width: 100%;
      height: 100%;
      max-height: 250px;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .info {
    width: 50%;
    padding-left: 1rem;
  }

  .label {
    color: var(--red);
    font-size: 1.5rem;
    text-align: left;
    font-weight: bold;
  }

  .description {
    display: flex;
    width: 100%;
    color: var(--white);;
  }
}

.home-widget-right {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-items: flex-end;
  padding: 1rem 0;

  .img {
    width: 40%;

    img {
      width: 100%;
      height: 100%;
      max-height: 250px;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .info {
    width: 50%;
    padding-right: 1rem;
  }

  .label {
    color: var(--red);
    font-size: 1.5rem;
    text-align: right;
    font-weight: bold;
  }

  .description {
    display: flex;
    text-align: right;
    width: 100%;
    color: var(--white);
  }
}

@media only screen and (max-width: 769px) {
  .home-widget-left {
    width: 100%;
    flex-direction: column;

    .img {
      width: 100%;
    }

    .info {
      width: 100%;
      padding: 0;
      text-align: justify;
    }

    .label {
      text-align: center;
      margin: 0;
      padding: 1rem 0;
    }

    .description {
      width: 100%;
      text-align: justify;
    }
  }

  .home-widget-right {
    width: 100%;
    flex-direction: column;

    .img {
      width: 100%;
    }

    .info {
      width: 100%;
      padding: 0;
      text-align: justify;
    }

    .label {
      text-align: center;
      margin: 0;
      padding: 1rem 0;
    }

    .description {
      width: 100%;
      text-align: justify;
    }
  }
}

@media only screen and (min-width: 769px) {
  .home-widget-left {
    .img {
      -webkit-mask-image: linear-gradient(to left, transparent 25%, black 75%);
      mask-image: linear-gradient(to left, transparent 25%, black 25%);
    }

    .info {
      transform: translateX(-150px);
    }
  }

  .home-widget-right {
    .img {
      -webkit-mask-image: linear-gradient(to right, transparent 25%, black 75%);
      mask-image: linear-gradient(to right, transparent 25%, black 75%);
    }

    .info {
      transform: translateX(150px);
    }
  }
}
