.rules-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rules-page .banner {
  background-image: url('../../images/src7.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rules {
  display: flex;
  max-width: 1000px;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 1rem;
  color: var(--white);
  padding: 1rem 1rem;

  h1 {
    color: var(--red);
  }

  span {
    text-align: justify;
  }
}