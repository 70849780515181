.auth-error-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.auth-error-page .banner {
  background-image: url('../../images/src7.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.auth-error-page section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  width: 1000px;
  padding: 1rem;
  gap: .5rem;

  span {
    color: var(--white);
  }
}