.faq-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .banner {
    background-image: url('../../images/src1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  section {
    display: flex;
    max-width: 1000px;
    width: 1000px;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 1rem;
    color: var(--white);
    padding: 1rem 1rem;
  }

  .element {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
  }

  h1 {
    color: var(--red);
    background: var(--primary);
    padding: .5rem;
    border-radius: 3px;
    margin: 0;
    cursor: pointer;
  }

  h2 {
    color: var(--red);
    font-size: 1.25rem;
  }

  span {
    text-align: justify;
    font-size: 1rem;
  }

  span:first-of-type {
    margin-top: 0
  }

  img {
    width: 100%;
    margin: .5rem 0;
  }

  .collapse {
    background: var(--secondary);
    padding: .5rem;
    border-radius: 3px;
  }
}

