.donate-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .banner {
    background-image: url('../../images/src6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    display: flex;
    max-width: 900px;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 1rem;
    color: var(--white);
    padding: 1rem 1rem;
    gap: 1rem;
  }

  h1 {
    color: var(--red);
  }

  h2 {
    color: var(--red);
  }

  h4 {
    color: var(--red);
  }

  span {
    text-align: justify;
    font-size: 1rem;
  }

  span:first-of-type {
    margin-top: 0
  }
}