.howjoin-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    color: var(--red);
  }

  h2 {
    color: var(--red);
  }

  span {
    text-align: justify;
    font-size: 1rem;
  }

  span:first-of-type {
    margin-top: 0
  }

  img {
    width: 100%;
    margin: .5rem 0;
  }
}

