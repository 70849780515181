.lore-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.lore-page .banner {
  background-image: url('../../images/src4.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.lore {
  display: flex;
  max-width: 1000px;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 1rem;
  color: var(--white);
  padding: 1rem 1rem 0 1rem;

  span {
    margin: .5rem 0;
  }
}