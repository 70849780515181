.footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer {
    flex-grow: 3;
    width: 100%;
    padding: 36px 36px;
    background-color: var(--primary);
    box-sizing: border-box;
}

.footer-wrap {
  padding-bottom: 325px;
}

.footer .fit-content::before {
    background: url('../../images//logo192.png') left no-repeat;
    width: 75px;
    height: 75px;
    content: '';
    z-index: 50;
    position: absolute;
    background-size: 75px;
    right: 36px;
    padding: auto 0;
}

.discord-logo {
    height: .75rem;
}

.link-section {
    display: flex;
    justify-content: right;
    width: 600px;
}

.link-footer {
    padding: 0;
    margin: 0;
}

.brand-section {
    width: 200px;
    text-align: right;

    img{
        height: 36px;
        margin-bottom: 16px;
    }
}

.brand-section .icon-button{
    width: 24px;
    height: 24px;
}

.coppyright-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 36px 36px;
    color: var(--white);
    background-color: var(--secondary);
    box-sizing: border-box;
}

.coppyright-bi {
    width: 70%;
}

.coppyright-rights {
    width: 30%;
    text-align: right;
}

.footer-widget-title {
    font-size: 26px;
    font-weight: 400;
    color: var(--darkblue);
}

.footer-widget {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33.3%;
}

@media only screen and (max-width: 769px) {
    .footer .fit-content::before {
        content: none;
    }
    .footer-widget-title {
        font-size: 18px;
    }
}

@media only screen and (max-width: 900px) {
    .coppyright-section .fit-content {
        display: block;
        text-align: center;
        width: 100%;
    }
    .coppyright-bi {
        width: 100%;
    }
    .coppyright-rights {
        width: 100%;
        text-align: center;
    }
    .brand-section {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 36px;
        margin-top: 16px;
    }
    .footer-wrap {
        padding-bottom: 355px;
    }
}

@media only screen and (max-width: 847px) {
    .footer-wrap {
        padding-bottom: 375px;
    }
}

@media only screen and (max-width: 591px) {
    .footer-wrap {
        padding-bottom: 370px;
    }
}

@media only screen and (max-width: 480px) {
    .footer-wrap {
        padding-bottom: 390px;
    }
}

@media only screen and (max-width: 424px) {
    .footer-wrap {
        padding-bottom: 500px;
    }
}