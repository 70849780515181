.user-bar-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
  gap: .5rem;
}

.user-bar-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}